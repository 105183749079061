<template lang="html">
  <PrivacypolicyEn />
</template>

<script>
import PrivacypolicyEn from "../components/privacypolicy/PrivacypolicyEn.vue";

export default {
  components: {
    PrivacypolicyEn,
  },
  created() {
    // this.$store.commit("muttHideHeader", "wa");
  },
};
</script>

<style lang="scss" scoped>
.eula {
  text-align: justify;

  b {
    font-weight: bold;
  }
}
.inner {
  @media screen and (max-width: 599px) {
    margin: 20px 0 40px;
  }
}
</style>
