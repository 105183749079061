<template>
  <section class="mx-auto my-16 md:my-24 px-4 lg:px-0 w-full lg:w-1024">
    <div class="inner">
      <p class="eula">
        <br /><b>neo Privacy Policy</b><br />
        <b>Effective Date: Jul 14, 2023</b><br /><br />
        We are committed to protecting your privacy. Nexgen English Online Co.
        (hereinafter Nexgen) collects, processes, and uses personal data for the
        following purposes. Any time you interact with Nexgen, such as through
        our websites, when you use neo products and services, or when you call
        our sales or support representatives, Nexgen may collect and use your
        personal information.

        <br /><br /><b>Why we collect personal information?</b><br /><br />
        Nexgen collects data from you, through our interactions with you and through our neo products and services. A portion of this data comes from you directly, and some comes from analyzing data about how you interact with, use, and experience our products. Depending on how you interact with Nexgen, we collect different types of data.<br /><br />
        Nexgen uses the data we collect to provide you with a rich, interactive, and personalized study experience. In particular, we use data to:<br /><br />
        <ul>
          <li style="list-style-type: circle;">Provide more of the content that you need on your journey to achieve English-level certification and less content where neo has determined you have already mastered the required content.</li>
          <li style="list-style-type: circle;">Provide superior support and updates for our neo products, including troubleshooting.</li>
          <li style="list-style-type: circle;">Develop and improve our neo products.</li>
          <li style="list-style-type: circle;">Offer Nexgen products and services that are tailored to your needs.</li>
          <li style="list-style-type: circle;">Advertise and market to you, which may include product announcements, promotional communications, updates, targeted advertising, and relevant offers.</li>
          <li style="list-style-type: circle;">Your data is also used to analyze your performance, to comply with legal obligations, and to conduct research, which results in enhanced and updated products.</li>
        </ul>
        <br>
        If you subscribe to neo electronic mailing lists, neo may periodically send you the latest information on products and services. If you wish to unsubscribe, you can do so at any time.

        <br /><br /><b>What information do we collect?</b><br /><br />
        We may collect personal information related to your situation, such as your name, phone number, email address, and contact preferences. Additionally, we collect information about your neo product, such as the date of purchase and support issues. <br><br>
        Nexgen products and services are provided to schools with the following restrictions:
        <br><br>

        <ul>
          <li style="list-style-type: circle;">Student personal data will only be collected and used for authorized educational or school purposes.</li>
          <li style="list-style-type: circle;">Data about students will not be sold or rented.</li>
          <li style="list-style-type: circle;">A student's personal profile will not be maintained except to support authorized educational or school purposes.</li>
          <li style="list-style-type: circle;">Any vendors with whom we share student personal data in order to deliver the educational service, if any, are required to implement these same commitments.</li>
        </ul>

        <br /><b>How do we process the information we collect?</b><br /><br />
        We use both automated and manual methods to process personal data. In addition to automated methods, we use artificial intelligence (AI), which enables computers to analyze student performance, thereby delivering lessons that are better suited to the student. We manually review computer-aided predictions and inferences to build, train, and improve the accuracy of our AI/machine-Learning methods.

        <br /><b>When do we disclose your information?</b><br /><br />
        Nexgen is committed to protecting your privacy when you use our products or services. Nexgen does not share your personal information with companies, organizations, or individuals outside of Nexgen without your consent. Nevertheless, Nexgen may be required to comply with laws, respond to legal processes, protect our customers, safeguard lives, maintain the security of our products, and protect Nexgen and its customers' rights and property.

        <br /><br /><b>How do we protect your personal information?</b
        ><br /><br />
        Your personal information is safeguarded by administrative, technical, and physical measures to prevent loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.

        <br /><br /><b>Removal of Personal Identifiable Information</b
        ><br /><br />
        We retain the minimum amount of personal information necessary to allow controlled access to our services and to validate certificates earned. If you desire to “OPT-OUT” and to have your Personal Identifiable Information removed from our databases, you may send your request to legal@nexgenenglishonline.co and request the removal of your personal information by stating, “I have read, understand, and agree with the Removal of Personal Identifiable Information clause.” We will normally remove your Personal Identifiable Information within one business day of receiving your request, unless we have to retain it in order to comply with applicable legal obligations or resolve disputes.<br /><br />
        If you request the removal of your Personal Identifiable Information before course completion, you will not be able to continue studying. Furthermore, if you receive a neo certificate and later request that your Personal Identifiable Information be removed, we will not be able to process any subsequent requests for certificate validation because we will not have any information verifying that you earned a certificate.

        <br /><br /><b>Privacy questions</b><br /><br />
        If you have questions about our customer privacy policy or data processing, please contact us at <b>support@nexgenenglishonline.co</b>

        <br /><br /><b>Note to Organizational Users</b
        ><br /><br />
        Please inform your users of Nexgen's privacy policies if you represent an organization that uses Nexgen's products and services. Our policy statement applies to Nexgen products and services provided directly by Nexgen or Nexgen authorized organizations.
      </p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.eula {
  text-align: justify;

  b {
    font-weight: bold;
  }
}
</style>