<template lang="html">
  <PrivacypolicyEn />
</template>

<script>
import PrivacypolicyEn from "../components/privacypolicy/PrivacypolicyEn.vue";

export default {
  components: {
    PrivacypolicyEn
  }
}
</script>

<style lang="scss" scoped>
  .eula {
    text-align: justify;

    b {
      font-weight: bold;
    }
  }
</style>
